/* ======================================================================== */
/* 59. SectionMasthead */
/* ======================================================================== */
class SectionMasthead extends ScrollAnimation {
	constructor({
		target,
		scope
	}) {
		super({
			target,
			scope
		});
	}

	run() {
		this._fixedMasthead();
		if (this._hasAnimationScene(this.$el)) {
			this.setAnimation();
			this.animate();
		}
	}

	_fixedMasthead() {
		const
			$fixedMasthead = this.$el.filter('.section-masthead_fixed'),
			speed = $fixedMasthead.attr('data-arst-scroll-fixed-speed'),
			duration = speed ? speed * window.innerHeight : window.innerHeight * 0.4;

		if ($fixedMasthead.length) {
			const
				tl = new gsap.timeline(),
				$background = $fixedMasthead.find('.section-masthead__background');

				tl.fromTo($fixedMasthead, {
					autoAlpha: 1
				}, {
						autoAlpha: 0
				}, 'start');

			if ($background.length && !isBrowserFirefox()) {
				tl.fromTo($background, {
					scale: 1,
					transformOrigin: 'center center'
				}, {
					scale: 1.1,
					transformOrigin: 'center center',
						overwrite: 'all'
				}, 'start');
			}

			new $.ScrollMagic.Scene({
					triggerElement: $fixedMasthead.next(),
					triggerHook: 'onEnter',
					reverse: true,
					duration
				})
				.setTween(tl)
				.setPin($fixedMasthead, {
					pushFollowers: false
				})
				.addTo(window.SMController);
		}
	}

	setAnimation() {
		this.$subheading = this.$el.find('.section-masthead__subheading');
		this.$heading = this.$el.find('.section-masthead__heading');
		this.$text = this.$el.find('.section-masthead__text');
		this.$bgWrapper = this.$el.find('.section-image__wrapper');
		this.$itemsMeta = this.$el.find('.section-masthead__meta');
		this.$headline = this.$el.find('.section__headline');
		this.$background = this.$el.find('.section-masthead__background:not(.js-cancel-animation)').find('.section-image__wrapper');
		this.$bg = this.$el.find('.section-masthead__bg');
		this.$wrapperbutton = this.$el.find('.section-masthead__wrapper-button');
		this.$wrapperSD = this.$el.find('.section-masthead__wrapper-scroll-down');
		this.$overlay = this.$el.find('.section-masthead__background.js-cancel-animation').find('.section-masthead__overlay');

		this.$mask = this.$el.find('.mask-reveal');
		this.$maskLayer1 = this.$el.find('.mask-reveal__layer-1');
		this.$maskLayer2 = this.$el.find('.mask-reveal__layer-2');

		gsap.set(this.$bg, {
			scaleY: 0,
			transformOrigin: 'bottom center'
		});

		gsap.set(this.$overlay, {
			autoAlpha: 0
		});

		gsap.set(this.$headline, {
			scaleX: 0
		});

		gsap.set(this.$wrapperbutton, {
			y: 30,
			autoAlpha: 0
		});

		gsap.set(this.$wrapperSD, {
			y: 30,
			autoAlpha: 0.01
		});

		gsap.effects.hideChars(this.$el, {
			x: 0,
			y: '100%',
			autoAlpha: 1,
			duration: 0,
		});

		if (this.$background.length && this.$mask.length) {
			gsap.effects.setMask(this.$mask, {
				direction: 'down'
			});
		} else {
			gsap.set(this.$background, {
				scale: isBrowserFirefox() ? 1.0 : 1.05,
				transformOrigin: 'center center',
				autoAlpha: 0
			});
		}
	}

	animate() {
		const
			tl = new gsap.timeline(),
			$target = this.$el.filter('[data-arts-os-animation]'),
			from = getStaggerFrom($target);

		if (this.$bg.length && this.$background.length) {
			tl.to(this.$bg, {
					duration: 1,
					scaleY: 1,
					transformOrigin: 'top center',
					ease: 'expo.inOut'
				}, 'start')
				.to(this.$background, {
					duration: 2.4,
					autoAlpha: 1,
					scale: 1
				}, 'start');
		}

		if (this.$bg.length && !this.$background.length) {
			tl.to(this.$bg, {
				duration: 1,
				scaleY: 1,
				transformOrigin: 'top center',
				ease: 'expo.inOut'
			}, 'start');
		}

		if (!this.$bg.length && !this.$mask.length && this.$background.length) {
			tl
				.to(this.$background, {
					duration: 2.4,
					autoAlpha: 1,
					scale: 1
				}, '<0.2');
		}

		if (this.$background.length && this.$mask.length) {
			tl.animateMask(this.$mask, {}, '<0.2');
		}

		if (this.$overlay.length) {
			tl.to(this.$overlay, {
				autoAlpha: 1,
				duration: 1.2
			}, '<0.2');
		}

		tl.animateChars($target, {
			duration: 1.2,
			stagger: distributeByPosition({
				from: from === 'center' ? 'start' : from,
				amount: 0.2
			})
		}, '<0.2');

		tl.animateWords($target, {
				ease: 'power3.out',
				duration: 1.2,
				onStart: () => {
					this.$itemsMeta.addClass('animated');
				}
			}, '<0.2')
			.animateLines($target, {
				ease: 'power3.out',
				duration: 1.2,
				stagger: 0.06,
			}, '<0.2')
			.to(this.$wrapperbutton, {
				duration: 0.6,
				y: 0,
				autoAlpha: 1
			}, '<0.2')
			.to(this.$wrapperSD, {
				onStart: () => {
					new CircleButton({
						target: this.$wrapperSD.find('.js-circle-button')
					});
				},
				duration: 0.6,
				y: 0,
				autoAlpha: 1
			}, '<0.2')
			.animateHeadline(this.$headline, {
				duration: 0.6
			}, '0.4');

		this._createScene({
			element: $target,
			timeline: tl
		});
	}
}
