/* ======================================================================== */
/* 9. PJAX Animate Cloned Image */
/* ======================================================================== */
function PJAXAnimateClonedImage(data, duration = 1.7) {
  return new Promise((resolve, reject) => {
    window.dispatchEvent(new CustomEvent('arts/barba/transition/clone/before'));

    const
      tl = new gsap.timeline(),
      $nextContainer = $(data.next.container),
      $curtain = $('#js-page-transition-curtain'),
      $nextMasthead = $nextContainer.find('.section-masthead'),
      background = $nextMasthead.attr('data-background-color'),
      $target = $nextMasthead.find('.js-transition-img'),
      $clone = $('.clone'),
      bgClone = $clone.find('.js-transition-img__transformed-el'),
      bgTarget = $target.find('.js-transition-img__transformed-el');

    if (!$target.length || !$clone.length) {
      reject(true);
      return;
    }

      const {
        top,
        left,
        width,
        height,
      } = $target.get(0).getBoundingClientRect(),
      bgTargetProperties = bgTarget.css(['transform', 'width', 'height', 'transformOrigin', 'objectPosition', 'objectFit']);

      const
        targetTransform = $target.css('transform'),
        targetBorderRadius = $target.css('border-radius'),
        targetClippath = $clone.css('clip-path') === 'none' ? '' : 'circle(100% at center)',
        offsetTop = window.$body.offset().top + top;

      tl
        .setCurtain($curtain, {
          background
        })
        .set($clone, {
          maxWidth: '100%',
          maxHeight: '100%',
        })
        .set($nextContainer, {
          clearProps: 'position'
        })
        .add([
          gsap.to(bgClone, {
            paddingBottom: 0,
            transform: bgTargetProperties.transform,
            width: bgTargetProperties.width,
            height: bgTargetProperties.height,
            objectFit: bgTargetProperties.objectFit,
            objectPosition: bgTargetProperties.objectPosition,
            duration: 1.2,
            ease: 'expo.inOut',
            transition: 'none',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            autoRound: false,
            onComplete: () => {
              if (bgTarget.is('video') && bgClone.is('video')) {
                bgTarget.replaceWith(bgClone);
                setTimeout(() => {
                  new ArtsParallax({
                    target: $nextMasthead.find('[data-arts-parallax]'),
                    factor: 0.3,
                    ScrollMagicController: window.SMController,
                    SmoothScrollBarController: window.SB
                  });
                }, 200);
              }
            }
          }),
          gsap.to($clone, {
            transform: targetTransform,
            transformOrigin: 'center center',
            top: offsetTop,
            left,
            width,
            height,
            duration: 1.2,
            ease: 'expo.inOut',
            transition: 'none',
            borderRadius: targetBorderRadius,
            clipPath: targetClippath,
            autoRound: false,
            onComplete: () => {
              Scroll.scrollTo({
                x: 0,
                y: 0,
                duration: 0
              });
            }
          }),
          gsap.effects.moveCurtain($curtain, {
            y: '0%',
            duration: 1.2
          }),
        ])
        .to($nextContainer, {
          duration: 0.2,
          clearProps: 'all',
          autoAlpha: 1,
        })
        .set(window.$body, {
          clearProps: 'background-color'
        })
        .setCurtain($curtain)
        .add(() => {
          resolve(true);
        })
        .totalDuration(duration)
        .timeScale(window.theme.animations.timeScale.ajaxFlyingImageTransition || 1);

  });
}
