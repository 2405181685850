/* ======================================================================== */
/* 55. SectionGrid */
/* ======================================================================== */
class SectionGrid extends ScrollAnimation {

	constructor({
		scope,
		target,
	}) {
		super({
			scope,
			target
		});
	}

	set() {
		this.$items = this.$el.find('.section-grid__item');
		this.$filter = this.$el.find('.js-filter');
		this.$filterAjax = this.$el.find('.js-grid-ajax__filter');
		this.filterAjaxActiveItemIndex = this.$filterAjax.find('.filter__item_active').index();
		this.$filterUnderline = this.$filter.find('.filter__underline');
		this.$grid = this.$el.find('.js-grid');
		this.$gridItems = this.$grid.find('.js-grid__item');

		this.$mask = this.$el.find('.mask-reveal');
		this.$maskLayer1 = this.$el.find('.mask-reveal__layer-1');
		this.$maskLayer2 = this.$el.find('.mask-reveal__layer-2');
		this.$captions = this.$el.find('.figure-image__wrapper-caption');

		// pagination elements
		this.$pagination = this.$el.find('.pagination');
		this.$prev = this.$pagination.find('a.page-numbers.prev');
		this.$next = this.$pagination.find('a.page-numbers.next');
		this.$pages = this.$pagination.find('.nav-links__container a.page-numbers');

		if (typeof window.theme === 'undefined' || typeof window.theme.ajaxURL === 'undefined') {
			this._bindPaginationEvents();
		}

		if (this._hasAnimationScene(this.$el)) {

			if (this.$mask.length) {
				gsap.effects.setMask(this.$mask);
				gsap.set(this.$captions, {
					y: 30,
					autoAlpha: 0,
				});
			} else {
				gsap.effects.setJump(this.$items);
			}

			gsap.set(this.$el, {
				autoAlpha: 1
			});
		}

		if (!this.$filterUnderline.hasClass('js-cancel-animation')) {
			gsap.set(this.$filterUnderline, {
				scaleX: 0
			});
		}
	}

	run() {
		const
			masterTL = new gsap.timeline(),
			colsDesktop = parseInt(this.$el.data('grid-columns'), 10) || 1,
			colsTablet = parseInt(this.$el.data('grid-columns-tablet'), 10) || 1,
			colsMobile = parseInt(this.$el.data('grid-columns-mobile'), 10) || 1,
			lg = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.lg - 1 : 1024,
			md = window.elementorFrontend ? window.elementorFrontend.config.breakpoints.md - 1 : 767;

		let cols = colsDesktop;

		this._bindGridFilter();

		masterTL.to(this.$filterUnderline, {
			scaleX: 1,
			transformOrigin: 'left center',
			ease: 'expo.inOut',
			duration: 0.6,
			delay: 0.3
		});

		if (!this._hasAnimationScene(this.$el)) {
			return;
		}

		if (window.Modernizr.mq('(max-width: ' + lg + 'px)')) {
			cols = colsTablet;
		}

		if (window.Modernizr.mq('(max-width: ' + md + 'px)')) {
			cols = colsMobile;
		}

		for (let index = 0; index < this.$items.length; index = index + cols) {

			let
				$array = this.$items.slice(index, index + cols),
				tl = new gsap.timeline();

			if (this.$mask.length) {
				tl.to($array.find(this.$maskLayer1), {
					y: '0%',
					duration: 0.9,
					ease: 'power4.inOut',
					stagger: 0.15,
				}, 'start')
				.to($array.find(this.$maskLayer2), {
					y: '0%',
					duration: 0.9,
					ease: 'power4.inOut',
					stagger: 0.15,
				}, 'start')
					.to($array.find(this.$maskLayer2), {
					duration: 1.35,
					transformOrigin: 'center center',
					scale: 1,
				}, 'start')
				.to($array.find(this.$captions), {
					duration: 0.6,
					y: 0,
					autoAlpha: 1
				}, '-=0.6')
			} else {
				tl.animateJump($array, {
					stagger: 0.15
				}, 'start');
			}

			this._createScene({
				element: index === 0 ? this.$el : $array[0],
				triggerHook: 0.9,
				timeline: tl,
				reveal: false
			});

		}

		this._createScene({
			element: this.$el,
			timeline: masterTL
		});
	}

	_bindPaginationEvents() {
		const self = this;

		this.$prev.off('click').on('click', (e) => {
			e.preventDefault();

			if (window.theme.posts.currentPage > 1) {
				this._getPostsAjax(parseInt(window.theme.posts.currentPage, 10) - 1);
			}
		});
		this.$next.off('click').on('click', (e) => {
			e.preventDefault();

			if (window.theme.posts.currentPage < window.theme.posts.totalPages) {
				this._getPostsAjax(parseInt(window.theme.posts.currentPage, 10) + 1);
			}
		});
		this.$pages.off('click').on('click', function (e) {
			e.preventDefault();
			self._getPostsAjax(parseInt(this.textContent, 10));
		});
	}

	_bindGridFilter() {
		const
			self = this,
			event = new CustomEvent('arts/grid/filter');

		if (!this.$grid.length) {
			return;
		}

		this.filter = this._createFilter();
		this.filterAJAX = this._createFilter();
		this.grid = this._createGrid();

		if (this.$filter.length) {
			this.filter.setActiveItem(0, 0);
			this.filter.$items.on('click', function (e) {
				const
					$el = $(this),
					filterBy = $el.data('filter'),
					isLink = $el.is('a');

				if (!isLink) {
					if (filterBy === '*') {
						self.$grid.removeClass('grid_filtered');
					} else {
						self.$grid.addClass('grid_filtered');
					}
				}

				if (isLink && window.theme.ajax.enabled) {
					e.preventDefault();
				}

				self.grid.isotope({
					filter: filterBy
				});
			});

			self.grid.on('arrangeComplete', () => {
				window.dispatchEvent(event);
			});
		}

		if (this.$filterAjax.length) {
			this.filterAJAX.setActiveItem(this.filterAjaxActiveItemIndex, 0);
		}
	}

	_createFilter() {
		return new Filter({
			scope: this.$scope,
			target: this.$filter
		});
	}

	_createGrid() {
		return new Grid({
			target: this.$grid
		});
	}

	_renderGrid($html) {
		this.$gridItems.remove(); // remove current page items from grid
		this.$grid
			.prepend($html)
			.isotope('prepended', $html)
			.isotope({
				filter: '*'
			});
	}

	_renderPagination($html) {
		this.$pagination.replaceWith($html);
	}

	_renderFilter($html) {
		this.$filter.replaceWith($html);
	}

	_getPostsAjax(page = 1) {
		$.ajax({
			url: window.theme.ajaxURL, // AJAX handler
			data: {
				'action': 'get_posts',
				page,
				totalPages: window.theme.posts.totalPages,
			},
			type: 'POST',
			beforeSend: () => {
				window.$body.addClass('cursor-progress');
				this.$el.addClass('pointer-events-none');
				this.$el.removeAttr('data-arts-os-animation');
				Scroll.scrollTo({
					x: 0,
					y: this.$el.offset().top - $('#page-header').height(),
					duration: 800
				});
			},
			success: (data) => {
				const
					$data = $(data),
					$gridItems = $data.find('.js-grid__item'),
					$pagination = $data.find('.pagination'),
					$filter = $data.find('.js-filter');

				this._renderFilter($filter);
				this._renderPagination($pagination);
				this._renderGrid($gridItems);
				this.set(); // refresh elements
				new SectionGrid({
					target: this.$el,
					scope: window.$document
				});
				this._bindEvents() // rebind events
				window.theme.posts.currentPage = parseInt(page); // refresh current page
			},
			complete: (data) => {
				this.$el.removeClass('pointer-events-none');
				window.$body.removeClass('cursor-progress');
			}
		});
	}

}
